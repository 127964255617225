import { NbMenuItem } from "@nebular/theme";
import { Injectable } from "@angular/core";
import { isArray } from "rxjs/internal-compatibility";
const dashboard = "Dashboard";
const units = "Units";
const contracts = "Contracts";
const invoices = "Collections";
const payers = "Payers";
const transfers = "Transfers";
const reports = "Reports";
const maintenance = "Maintenance";
const mobileApp = "Mobile App";
let english = "English";

export const Menu: NbMenuItem[] = [
  {
    title: dashboard,
    icon: { icon: "home", pack: "home" },
    link: "/pages/dashboard/super",
  },
  {
    title: units,
    icon: { icon: "unit", pack: "unit" },
    link: "/pages/units",
  },
  {
    title: contracts,
    icon: { icon: "contracts", pack: "contracts" },
    link: "/pages/contracts",
  },
  {
    title: invoices,
    icon: { icon: "invoice", pack: "invoice" },
    link: "",
    data: [
      "/pages/invoices",
      "/pages/single-invoice",
      "/pages/group-invoice",
      "/pages/receipts",
    ],
  },
  {
    title: payers,
    icon: { icon: "ten", pack: "ten" },
    link: "/pages/payers",
  },
  {
    title: transfers,
    icon: { icon: "trans", pack: "trans" },
    link: "/pages/transfers",
  },
  {
    title: reports,
    icon: { icon: "report", pack: "report" },
    link: "/pages/reports",
  },
  {
    title: maintenance,
    icon: { icon: "maintenance", pack: "maintenance" },
    link: "/pages/maintenance",
  },
  {
    title: mobileApp,
    icon: { icon: "approve_request", pack: "approve_request" },
    link: "",
    data: ["/pages/approval_request", "/pages/announcement"],
  },

  {
    title: english,
    data: "change-language",
    icon: { icon: "lang", pack: "lang" },
  },
];
@Injectable({
  providedIn: "root",
})
export class CompanyMenu {
  constructor() {}
  applyConditionToMenuItems(items?): NbMenuItem[] {
    const originalMenu = [...Menu].map((item) => ({ ...item }));
    let pages = JSON.parse(localStorage.getItem("pages"));
    if (pages) {
      return this.filterMenuItems(originalMenu, pages);
    } else if (items) {
      return this.filterMenuItems(originalMenu, items);
    }

    return [];
  }

  private filterMenuItems(
    menuItems: NbMenuItem[],
    pages: string[]
  ): NbMenuItem[] {
    return menuItems
      .map((menuItem: NbMenuItem) => {
        return menuItem;
      })
      .filter((menuItem: NbMenuItem) => {
        const hasValidChildren =
          menuItem.children && menuItem.children.length > 0;

        if (
          menuItem.data &&
          menuItem.data.length > 0 &&
          isArray(menuItem.data)
        ) {
          const isAnyDataInPages = menuItem.data.some((link) =>
            pages.includes(link)
          );
          if (!isAnyDataInPages) {
            return false;
          }
        }

        if (pages.includes(menuItem.link) || !menuItem.link) {
          return true;
        }

        return hasValidChildren;
      });
  }
}
