import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-print-contract",
  templateUrl: "./print-contract.component.html",
  styleUrls: ["./print-contract.component.scss"],
})
export class PrintContractComponent implements OnInit {
  imgSrc: string = "../../../../../assets/images/agri-report/agri-report.jpg";

  constructor(private location: Location, private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.printImage();
    }, 200);
  }

  printImage(): void {
    window.print();
    window.onafterprint = () => {
      window.history.back();
      this.location.back();
      this.router.navigate(['../../']);
    };
  }
}
