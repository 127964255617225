import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {
  value : Subject<any> = new Subject()
  PageNum : Subject<any> = new Subject()

  private pageSource = new BehaviorSubject('');
  currentPage = this.pageSource.asObservable();

  constructor() { }

  get valueDate():Subject<any>{
    return this.value
  }
  set valueDate(newValue:Subject<any>){
    this.value = newValue
  }
  changeValue(val){
    this.valueDate.next(val)
  }
  changePage(pageNumber: any) {
    this.pageSource.next(pageNumber)
  }
}
